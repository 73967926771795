import React, { Component } from 'react';
import './QuestionnairePage.scss';

import QuestionnaireField from '../QuestionnaireField/QuestionnaireField.js';

class QuestionnairePage extends Component {
  render() {
    let { pages, fields, pageCurrentId, setFieldFilled, setNextPage, submitFields } = this.props;

    let [pageCurrent] =       pages.filter(page => page.id === pageCurrentId);
    let fieldsOfCurrentPage = fields.filter(field => (
      field.onPage === pageCurrentId) &&
      !field.isHidden
    );
    let countOfFields =       0;
    let countOfFilledFields = 0;
    
    fieldsOfCurrentPage.map(fieldOfPage => {

      if (fieldOfPage.type === 'checkbox' ||
        fieldOfPage.isHidden) {
        return false
      }

      countOfFields++;

      if (fieldOfPage.value) {
        countOfFilledFields++;
      }

      return false;
    });

    let isAllFieldsFilled = countOfFields === countOfFilledFields ? true : false;

    let nextPage =    pages.filter(page => page.id === pageCurrentId + 1 ? true : false);
    let isLastPage =  false;
      
    if (!nextPage.length) {
      isLastPage = true;
    }

    return (
      <div className="QuestionnairePage">
        <div className="container">
          {
            pageCurrent.title ?
            <h2 className="QuestionnairePageTitle">
              {pageCurrent.title}
            </h2>
            : null
          }
          <div className="QuestionnaireFields">
            <div className="row">
              {
                fieldsOfCurrentPage.map(field => 
                  <div
                    className="col-sm-6"
                    key={field.name}
                  >
                    <QuestionnaireField
                      field={field}
                      setFieldFilled={setFieldFilled}
                    />
                  </div>
                )
              }
            </div>
          </div>
          <div
            className={'QuestionnaireButton ' + (isAllFieldsFilled ? '' : 'QuestionnaireButtonDisabled')}
            onClick={isLastPage ? submitFields : setNextPage}
          >
              <span className="QuestionnaireButtonText">
                {isLastPage ? 'Submit' : 'Next'}
              </span>
              <img
                className="QuestionnaireButtonIcon"
                src="/images/arrow-right.svg"
                alt={isLastPage ? 'Submit' : 'Next'}
                width="9"
              />
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionnairePage;