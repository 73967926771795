import React, { Component } from 'react';

class QuestionnaireFieldCheckbox extends Component {

  constructor (props) {
    super(props);

    this.catchToSetFieldFilled = this.catchToSetFieldFilled.bind(this);
  }

  catchToSetFieldFilled (event) {
    let field =   {};

    field.name =  event.target.name;
    field.value = event.target.value;

    this.props.setFieldFilled(field);
  }

  render() {

    let { name, label, options, values} = this.props;

    return (
      <div className="QuestionnaireFieldRadio">
        <div className="QuestionnaireFieldLabelTextLarge">
          {label}
        </div>
        <div className="QuestionnaireFieldRadioLabels">
          {options.map(option => 
            <label key={option} className="QuestionnaireFieldRadioLabel">
              <input
                className="QuestionnaireFieldRadioInput"
                type="checkbox"
                name={name}
                value={option}
                checked={values.includes(option)}
                onChange={this.catchToSetFieldFilled}
              />
              <span className="QuestionnaireFieldRadioIcon QuestionnaireFieldRadioIconCheckbox" />
              <span className="QuestionnaireFieldRadioText">
                {option}
              </span>
            </label>
          )}
        </div>
      </div>
    )
  }
}

export default QuestionnaireFieldCheckbox;