import React, { Component } from 'react';
import './QuestionnaireField.scss';

import QuestionnaireFieldText from      './QuestionnaireFieldText.js';
import QuestionnaireFieldRadio from     './QuestionnaireFieldRadio.js';
import QuestionnaireFieldCheckbox from  './QuestionnaireFieldCheckbox.js';

class QuestionnaireField extends Component {
  render() {

    let { field, setFieldFilled } = this.props;

    return (
      <>
        {
          field.type === 'text' ?
          <QuestionnaireFieldText
            name={field.name}
            label={field.label}
            value={field.value}
            setFieldFilled={setFieldFilled}
          />
          : field.type === 'radio' ?
          <QuestionnaireFieldRadio
            name={field.name}
            label={field.label}
            options={field.options}
            value={field.value}
            setFieldFilled={setFieldFilled}
          />
          : field.type === 'checkbox' ?
          <QuestionnaireFieldCheckbox
            name={field.name}
            label={field.label}
            options={field.options}
            values={field.value}
            setFieldFilled={setFieldFilled}
          />
          : null
        }
      </>
    )
  }
}

export default QuestionnaireField;