import React, { Component } from 'react';
import './QuestionnaireHeader.scss';

import QuestionnaireProgress from '../QuestionnaireProgress/QuestionnaireProgress.js';

class QuestionnaireHeader extends Component {
  
  render() {

    let { fields } = this.props;

    return (
      <div className="QuestionnaireHeader">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="QuestionnaireHeaderTitle">
                Patient Questionnaire
                </h1>
            </div>
            <div className="col-sm-6">
              <QuestionnaireProgress fields={fields} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionnaireHeader;