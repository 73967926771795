import React, { Component } from 'react';

import './QuestionnaireProgress.scss';

class QuestionnaireProgress extends Component {

  countPercent () {

    let { fields } =    this.props;
    let countOfFields =       0;
    let countOfFilledFields = 0;

    fields.map(field => {

      if (
        field.type === 'checkbox' ||
        field.isHidden
      ) {
        return false
      }

      countOfFields++;

      if (field.value) {
        countOfFilledFields++;
      }

      return false;
    });

    return countOfFields ? Math.round(countOfFilledFields / countOfFields * 100) : 0;
  }

  render() {

    let percent = this.countPercent();

    return (
      <div className="QuestionnaireProgress">
        <div className="QuestionnaireProgressText">{percent + '%'}</div>
        <div className="QuestionnaireProgressBar">
          <div
            className="QuestionnaireProgressFilled"
            style={{width: percent + '%'}}
          />
        </div>
      </div>
    )
  }
}

export default QuestionnaireProgress;