import React, { Component } from 'react';

class QuestionnaireFieldText extends Component {

  constructor (props) {
    super(props);

    this.catchToSetFieldFilled = this.catchToSetFieldFilled.bind(this);
  }

  catchToSetFieldFilled (event) {
    let field =   {};

    field.name =  event.target.name;
    field.value = event.target.value;

    this.props.setFieldFilled(field);
  }

  render() {

    let { name, label, value} = this.props;

    return (
      <label className="QuestionnaireFieldText">
        <span className="QuestionnaireFieldLabelTextSmall">
          {label}
        </span>
        <input
          className="QuestionnaireFieldInputText"
          type="text"
          name={name}
          value={value}
          onChange={this.catchToSetFieldFilled}
        />
      </label>
    )
  }
}

export default QuestionnaireFieldText;