import React, { Component } from 'react';
import './App.scss';

import Questionnaire from './components/Questionnaire/Questionnaire.js';

class App extends Component {
  render () {
    return (
      <div className="App">
        <Questionnaire />
      </div>
    );
  }
}

export default App;