import React, { Component } from 'react';
import './Questionnaire.scss';

import QuestionnaireHeader from './QuestionnaireHeader/QuestionnaireHeader.js';
import QuestionnairePage from './QuestionnairePage/QuestionnairePage.js';

class Questionnaire extends Component {

  constructor (props) {
    super(props);

    this.state = {
      action:         '',
      pages:          [],
      fields:         [],
      pageCurrentId:  null
    }

    this.setFieldFilled = this.setFieldFilled.bind(this);
    this.setNextPage =    this.setNextPage.bind(this);
    this.submitFields =   this.submitFields.bind(this);

    let url = new URL(window.location.href);

    if (url.searchParams.get('reset') === '') {
      localStorage.removeItem('state');
    }
  }

  componentDidMount () {
    
    let storedStateStr = localStorage.getItem('state');

    if (storedStateStr) {

      let storeState = JSON.parse(storedStateStr);

      storeState.pageCurrentId = storeState.pages[0].id;

      this.setState(storeState);
    }
    else {
      fetch('/json/form.json')
        .then(response => response.json())
        .then(response => {

          response.pageCurrentId = response.pages[0].id;

          response.fields = response.fields.map(field => {
            field.value = field.type === 'checkbox' ? [] : '';
            return field;
          });

          this.setState(response);
        });
    }
  }

  setFieldFilled (fieldUpdated) {
    this.setState(state => {

      state.fields = state.fields.map(field => {
        if (field.name === fieldUpdated.name) {

          // If checkbox
          if (Array.isArray(field.value)) {
            if (field.value.includes(fieldUpdated.value)) {
              let indexOfValue = field.value.indexOf(fieldUpdated.value);
              field.value.splice(indexOfValue, 1);
            }
            else {
              field.value.push(fieldUpdated.value)
            }
          } 
          else {
            field.value = fieldUpdated.value;

            // Show/hide fields
            if (field.showHideFieldsNames) {
              field.showHideFieldsNames.map(showHideFieldName => {
                state.fields = state.fields.map(fieldForShowHide => {
                  if (fieldForShowHide.name === showHideFieldName) {

                    //Show
                    if (field.showFieldsIfOptionIs === fieldUpdated.value) {
                      fieldForShowHide.isHidden = false;
                    }
                    
                    // Hide
                    if (field.hideFieldsIfOptionIs === fieldUpdated.value) {
                      fieldForShowHide.isHidden = true;
                    }
                  }

                  return fieldForShowHide;
                });

                return false;
              });
            }
          }
        }

        return field;
      });

      return state;
    });
  }

  setNextPage () {
    this.setState(state => {
      state.pageCurrentId++

      localStorage.setItem('state', JSON.stringify(state));

      return state;
    });
  }

  submitFields () {
    let fileldsForSubmit = this.state.fields.filter(field => !field.isHidden);

    let data = {}

    fileldsForSubmit.map(field => {
        data = {
          ...data,
          [field.name]: field.value
        }

        return false;
    });

    fetch(this.state.action, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    })
    .then(response => response.json());
  }

  render () {

    let { pages, fields, pageCurrentId } = this.state;

    return (
      <div className="Questionnaire">
        <QuestionnaireHeader
          fields={fields}
        />
        {
          pages.length ?
          <QuestionnairePage
            pages={pages}
            fields={fields}
            pageCurrentId={pageCurrentId}
            setFieldFilled={this.setFieldFilled}
            setNextPage={this.setNextPage}
            submitFields={this.submitFields}
          />
          : null
        }
      </div>
    )
  }
}

export default Questionnaire;